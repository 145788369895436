
// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
$primary: #c9141a;

@import "../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
/* Disable hover effect on Dropdown.Toggle */
.no-hover-dropdown {
  background-color: transparent !important;
  border-color: transparent !important;
}

.no-hover-dropdown:hover,
.no-hover-dropdown:focus {
  background-color: transparent !important;
  border-color: transparent !important;
}